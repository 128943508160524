<template>
  <q-card style="width: 600px; max-width: 60vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        <small class="q-ml-sm text-size-sm">
          {{ PdmMaTypeEntity.TypeName!=""?"编辑套餐分类":"新增套餐分类" }}
        </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <q-input
          label="分类名称"
          dense
          outlined
          class="col-11"
          v-model="PdmMaTypeEntity.TypeName"
        /> 
      </div>
      <div class="row q-gutter-sm q-mb-sm">
        <q-input
          label="排序"
          dense
          outlined
          class="col-11"
          v-model="PdmMaTypeEntity.TypeSort"
        /> 
      </div>
    </q-card-section>
      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn flat label="取消" v-close-popup />
        <q-btn flat label="确定" @click="onOK" />
      </q-card-actions>      

  </q-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
// import select_hospInfo from "@/views/pdm/comm/hospInfo_select.vue";
export default {
  components: {
    // select_hospInfo,
  }, 
  props: {},
  computed: {
    ...mapState("PdmMaType", ["PdmMaTypeShowDlg", "PdmMaTypeEntity", "PdmMaTypeList"]),
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("PdmMaType", ["actUpdatePdmMaType", "actGetPdmMaTypeList"]),
    onOK() {
      this.actUpdatePdmMaType({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        TypeID: this.PdmMaTypeEntity.TypeID,
        Info: this.PdmMaTypeEntity,
      }).then((res) => {
        if (res.Code == 0) {
          // setTimeout(()=> {
          //   this.$emit("onLoadData");
          // }, 200);

          this.msg.success(res.Message);
        } else {
          this.msg.error(res.Message);
        }
      });
    },
  },
};
</script>
